






















































































import { Messages } from "@/models/enums/messages.enum";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "CustomerDetails",
  props: ["dataCustomerType", "customerNumber", "dataTaxInvoiceCode"],
  data() {
    return {
      formRules: {
        customerType: {
          label: "lbl_customer_type",
          name: "customerType",
          placeholder: "lbl_customer_type_placeholder",
          decorator: [
            "customerType",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        taxInvoiceCode: {
          label: "lbl_tax_invoice_code",
          name: "taxInvoiceCode",
          placeholder: "lbl_tax_invoice_code_placeholder",
          decorator: [
            "taxInvoiceCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        creditLimit: {
          label: "lbl_credit_limit",
          name: "creditLimit",
          placeholder: "lbl_credit_limit_placeholder",
          decorator: ["creditLimit"],
        },
        discount: {
          label: "lbl_discount",
          name: "discount",
          placeholder: "lbl_discount_placeholder",
          decorator: ["discount"],
        },
        customerNumber: {
          label: "lbl_customer_number",
        },
      },
    };
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
  },
});
