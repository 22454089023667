var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        [
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-top": "1rem" },
              attrs: { label: _vm.$t(_vm.formRules.customerType.label) }
            },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.customerType.decorator,
                      expression: "formRules.customerType.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.customerType.name,
                    placeholder: _vm.$t(_vm.formRules.customerType.placeholder),
                    allowClear: true
                  }
                },
                _vm._l(_vm.dataCustomerType, function(data, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.value) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.value) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-top": "1rem" },
              attrs: { label: _vm.$t(_vm.formRules.taxInvoiceCode.label) }
            },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.taxInvoiceCode.decorator,
                      expression: "formRules.taxInvoiceCode.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.taxInvoiceCode.name,
                    placeholder: _vm.$t(
                      _vm.formRules.taxInvoiceCode.placeholder
                    ),
                    allowClear: true
                  },
                  on: {
                    search: function(value) {
                      return this$1.$emit("handleSearchTaxInvoiceCode", value)
                    }
                  }
                },
                _vm._l(_vm.dataTaxInvoiceCode, function(data, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: data.code } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.code) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.code) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticStyle: { "margin-top": "1rem" },
              attrs: { label: _vm.$t(_vm.formRules.creditLimit.label) }
            },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.creditLimit.decorator,
                    expression: "formRules.creditLimit.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.creditLimit.name,
                  placeholder: _vm.$t(_vm.formRules.creditLimit.placeholder),
                  parser: _vm.reverseFormatNumber,
                  formatter: _vm.formatterNumber,
                  precision: _vm.storeBaseDecimalPlace
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.discount.label) } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.discount.decorator,
                    expression: "formRules.discount.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.discount.name,
                  placeholder: _vm.$t(_vm.formRules.discount.placeholder),
                  parser: _vm.reverseFormatNumber,
                  formatter: _vm.formatterNumber,
                  precision: _vm.storeBaseDecimalPlace
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.customerNumber.label) } },
            [_c("p", [_vm._v(_vm._s(_vm.customerNumber || ""))])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }